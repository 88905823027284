import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import logo from "../../../../assets/images/cardiovita_logo.jpg";
import Spinner from "../../../SharedComponents/Spinner";
import axios from "axios";
import config from "../../../../config.json";
import { useNavigate } from "react-router-dom";

export default function SpecReviewPdf({ medications, specReview, doctor, lang, isMultiLang }) {
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState({});
    const [fontSize1, setFontSize1] = useState(14);
    const [fontSize2, setFontSize2] = useState(14);
    const [fontSize3, setFontSize3] = useState(14);
    const [fontSize4, setFontSize4] = useState(14);
    const [fontSize5, setFontSize5] = useState(14);
    const [fontSize6, setFontSize6] = useState(14);
    const examDate = new Date(specReview.exam_date);
    const navigate = useNavigate();

    useEffect(() => {
        getPatient();
    }, []);

    const getPatient = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/patients/${specReview.patient_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                const patient = res.data;
                patient.date_of_birth = new Date(res.data.date_of_birth);
                setPatient(patient);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
            <div className="background-wrapper_print justify-content-center" style={{ width: "950px", height: "auto", backgroundColor: "inherit", marginBottom: "30px" }}>
                <form style={{ width: "100%" }}>
                    <div className="background-form-wrapper_print">
                        <div className="d-flex flex-column align-items-center w-100">
                            <h1 className="text-center border-0">СПЕЦИЈАЛИСТИЧКИ ПРЕГЛЕД</h1>
                            {isMultiLang ? <h1 className="m-0 text-center border-0">Ekzaminim specialistik</h1> : null}
                        </div>

                        <div className="w-100 d-flex mt-4 justify-content-between">
                            <div className="d-flex" style={{ width: "55%" }}>
                                <div style={{ width: "28%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ИМЕ И ПРЕЗИМЕ </p>
                                    {isMultiLang ? <p className="m-0">Emri dhe Mbiemri</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "72%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {lang === "al" ? patient.name_latin : patient.name_cyrilic}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "43%" }}>
                                <div style={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">БРОЈ НА ПАЦИЕНТ</p>
                                    {isMultiLang ? <p className="m-0">Numri i pacientit</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "60%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {`CV${patient.id}`}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-3 pt-0 justify-content-between">
                            <div className="d-flex" style={{ width: "55%" }}>
                                <div style={{ width: "32%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ДАТУМ НА РАЃАЊЕ</p>
                                    {isMultiLang ? <p className="m-0">Datëlindja</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "68%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {patient.date_of_birth
                                        ? `${patient.date_of_birth.getDate()}/${patient.date_of_birth.getMonth() + 1 < 10 ? "0" + (patient.date_of_birth.getMonth() + 1) : patient.date_of_birth.getMonth() + 1}/${patient.date_of_birth.getFullYear()}`
                                        : ""}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "43%" }}>
                                <div style={{ width: "42%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ДАТУМ НА ПРЕГЛЕД</p>
                                    {isMultiLang ? <p className="m-0">Data e ekzaminimit</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "58%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {`${examDate.getDate()}/${examDate.getMonth() + 1 < 10 ? "0" + (examDate.getMonth() + 1) : examDate.getMonth() + 1}/${examDate.getFullYear()}`}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-3 pt-0 justify-content-between">
                            <div className="d-flex" style={{ width: "55%" }}>
                                <div style={{ width: "36%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">МЕСТО НА ЖИВЕЕЊЕ</p>
                                    {isMultiLang ? <p className="m-0">Vendbanimi</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "64%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {lang === "al" ? patient.city_latin : patient.city_cyrilic}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "43%" }}>
                                <div style={{ width: "25%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ПРЕГЛЕДАЛ</p>
                                    {isMultiLang ? <p className="m-0">Ekzaminoi</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "75%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {lang === "al" ? "Dr. " + doctor.name_latin : "Др. " + doctor.name_cyrilic}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-4" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Анамнестички податоци </span> <br />
                                {isMultiLang ? <span>Të dhënat anamnestike</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "208px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize1 + 5 + "px", fontSize: fontSize1 + "px" }} className="p-1">
                                {lang === "al" ? specReview.anamnestic_data_al : specReview.anamnestic_data}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize1} onChange={(e) => setFontSize1(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Објективен наод </span> <br />
                                {isMultiLang ? <span>Ekzaminimi objektiv</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "208px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize2 + 5 + "px", fontSize: fontSize2 + "px" }} className="p-1">
                                {lang === "al" ? specReview.objective_find_al : specReview.objective_find}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize2} onChange={(e) => setFontSize2(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">иследувања </span> <br />
                                {isMultiLang ? <span>Ekzaminime </span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "208px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize3 + 5 + "px", fontSize: fontSize3 + "px" }} className="p-1">
                                {lang === "al" ? specReview.investigation_al : specReview.investigation}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize3} onChange={(e) => setFontSize3(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="page-break" style={{ margin: isMultiLang ? "5rem" : "6rem" }}></div>

                        <div className="w-100 d-flex mt-10" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Дијагноза </span> <br />
                                {isMultiLang ? <span>Diagnoza</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "208px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize4 + 5 + "px", fontSize: fontSize4 + "px" }} className="p-1">
                                {lang === "al" ? specReview.diagnose_al : specReview.diagnose}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize4} onChange={(e) => setFontSize4(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        {/* <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Терапија </span> <br />
                                {isMultiLang ? <span>Terapia</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "208px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize5 + 4 + "px", fontSize: fontSize5 + "px" }} className="px-1">
                                {lang === "al" ? specReview.therapy_al : specReview.therapy}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize5} onChange={(e) => setFontSize5(Number(e.target.value))}>
                                <option value="13">13px</option>
                                <option value="12">12px</option>
                                <option value="11">11px</option>
                            </select>
                        </div> */}
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Терапија </span> <br />
                                {isMultiLang ? <span>Terapia</span> : null}
                            </div>
                            <div className="" style={{ border: "1px solid #212529", width: "76%", borderRadius: "5px", overflow: "hidden" }}>
                                <div style={{ width: "100%" }}>
                                    {medications.length > 0 ? (
                                        <>
                                            <div className="d-flex">
                                                <div className="py-1" style={{ width: "52%", borderRight: "1px solid #212529" }}></div>
                                                <div className="text-center py-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                    {isMultiLang ? (
                                                        <span>
                                                            Наутро
                                                            <br />
                                                            Mëngjes
                                                        </span>
                                                    ) : (
                                                        "Наутро"
                                                    )}
                                                </div>
                                                <div className="text-center py-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                    {isMultiLang ? (
                                                        <span>
                                                            Ручек
                                                            <br />
                                                            Mesditë
                                                        </span>
                                                    ) : (
                                                        "Ручек"
                                                    )}
                                                </div>
                                                <div className="text-center py-1" style={{ width: "16%", lineHeight: "16px" }}>
                                                    {isMultiLang ? (
                                                        <span>
                                                            Вечер
                                                            <br />
                                                            Mbrëmje
                                                        </span>
                                                    ) : (
                                                        "Вечер"
                                                    )}
                                                </div>
                                            </div>
                                            {medications.map((item, index) => {
                                                return (
                                                    <div className="d-flex" key={`therapy_${index}`} style={{ borderTop: "1px solid #212529" }}>
                                                        <div className="p-1" style={{ width: "52%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                            {item.medication}
                                                        </div>
                                                        <div className=" text-center p-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                            {item.morning}
                                                        </div>
                                                        <div className=" text-center p-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                            {item.lunch}
                                                        </div>
                                                        <div className=" text-center p-1" style={{ width: "16%", lineHeight: "16px" }}>
                                                            {item.evening}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <div className="d-flex align-items-center p-1">
                                            <div style={{ width: "50%" }}>Без терапија</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Препорака </span> <br />
                                {isMultiLang ? <span>Rekomandim</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "208px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize6 + 5 + "px", fontSize: fontSize6 + "px" }} className="p-1">
                                {lang === "al" ? specReview.recommendation_al : specReview.recommendation}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize6} onChange={(e) => setFontSize6(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex justify-content-end doctor-sign">
                            <div style={{ borderBottom: "1px solid #212529", width: "250px", paddingBottom: "50px" }}>
                                <div className="m-0 fw-bold">{lang === "al" ? "Dr." + doctor.name_latin : "Др." + doctor.name_cyrilic}</div>
                                <div className="m-0">{isMultiLang ? doctor.speciality_latin : doctor.speciality_cyrilic}</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
