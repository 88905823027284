import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import logo from "../../../../assets/images/cardiovita_logo.jpg";
import Spinner from "../../../SharedComponents/Spinner";
import axios from "axios";
import config from "../../../../config.json";
import eho1 from "../../../../assets/images/eho_1.jpg";
import eho2 from "../../../../assets/images/eho_2.jpg";
import { useNavigate } from "react-router-dom";

export default function CardioExamPdf({ medications, cardioExam, doctor, lang, isMultiLang }) {
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState({});
    const [fontSize1, setFontSize1] = useState(14);
    const [fontSize2, setFontSize2] = useState(14);
    const [fontSize3, setFontSize3] = useState(14);
    const [fontSize4, setFontSize4] = useState(14);
    const [fontSize5, setFontSize5] = useState(14);
    const [fontSize6, setFontSize6] = useState(14);
    const examDate = new Date(cardioExam.exam_date);
    const navigate = useNavigate();

    useEffect(() => {
        getPatient();
    }, []);

    const getPatient = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/patients/${cardioExam.patient_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                const patient = res.data;
                patient.date_of_birth = new Date(res.data.date_of_birth);
                setPatient(patient);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
            <div
                className="background-wrapper_print justify-content-center"
                style={{
                    width: "820px",
                    height: "auto",
                    backgroundColor: "inherit",
                    marginBottom: "30px",
                    fontSize: "15px",
                }}
            >
                <form style={{ width: "100%" }}>
                    <div className="background-form-wrapper_print">
                        <div className="d-flex flex-column align-items-center w-100">
                            <h1 className="text-center border-0">КАРДИОЛОШКИ ПРЕГЛЕД</h1>
                            {isMultiLang ? <h1 className="m-0 text-center border-0">Ekzaminim kardiologjik</h1> : null}
                        </div>

                        <div className="w-100 d-flex mt-4 justify-content-between">
                            <div className="d-flex" style={{ width: "55%" }}>
                                <div style={{ width: "28%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ИМЕ И ПРЕЗИМЕ </p>
                                    {isMultiLang ? <p className="m-0">Emri dhe Mbiemri</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "72%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {lang === "al" ? patient.name_latin : patient.name_cyrilic}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "43%" }}>
                                <div style={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">БРОЈ НА ПАЦИЕНТ</p>
                                    {isMultiLang ? <p className="m-0">Numri i pacientit</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "60%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {`CV${patient.id}`}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-3 pt-0 justify-content-between">
                            <div className="d-flex" style={{ width: "55%" }}>
                                <div style={{ width: "32%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ДАТУМ НА РАЃАЊЕ</p>
                                    {isMultiLang ? <p className="m-0">Datëlindja</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "68%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {patient.date_of_birth
                                        ? `${patient.date_of_birth.getDate()}/${patient.date_of_birth.getMonth() + 1 < 10 ? "0" + (patient.date_of_birth.getMonth() + 1) : patient.date_of_birth.getMonth() + 1}/${patient.date_of_birth.getFullYear()}`
                                        : ""}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "43%" }}>
                                <div style={{ width: "42%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ДАТУМ НА ПРЕГЛЕД</p>
                                    {isMultiLang ? <p className="m-0">Data e ekzaminimit</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "58%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {`${examDate.getDate()}/${examDate.getMonth() + 1 < 10 ? "0" + (examDate.getMonth() + 1) : examDate.getMonth() + 1}/${examDate.getFullYear()}`}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-3 pt-0 justify-content-between">
                            <div className="d-flex" style={{ width: "55%" }}>
                                <div style={{ width: "36%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">МЕСТО НА ЖИВЕЕЊЕ</p>
                                    {isMultiLang ? <p className="m-0">Vendbanimi</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "64%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {lang === "al" ? patient.city_latin : patient.city_cyrilic}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "43%" }}>
                                <div style={{ width: "25%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ПРЕГЛЕДАЛ</p>
                                    {isMultiLang ? <p className="m-0">Ekzaminoi</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "75%" }} className="d-flex align-items-end justify-content-center fw-bold">
                                    {lang === "al" ? "Dr. " + doctor.name_latin : "Др. " + doctor.name_cyrilic}
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex mt-3 pt-2" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Анамнестички податоци </span> <br />
                                {isMultiLang ? <span>Të dhënat anamnestike</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "115px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize1 + 5 + "px", fontSize: fontSize1 + "px" }} className="p-1">
                                {lang === "al" ? cardioExam.anamnestic_data_al : cardioExam.anamnestic_data}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize1} onChange={(e) => setFontSize1(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Објективен наод </span> <br />
                                {isMultiLang ? <span>Ekzaminimi objektiv</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "115px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize2 + 5 + "px", fontSize: fontSize2 + "px" }} className="p-1">
                                {lang === "al" ? cardioExam.objective_find_al : cardioExam.objective_find}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize2} onChange={(e) => setFontSize2(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Ехокардиографија </span> <br />
                                {isMultiLang ? <span>Ekokardiografija</span> : null}
                                <div style={{ overflow: "hidden" }}>
                                    <img src={eho1} alt="cardio" style={{ width: "110%", height: "150px" }} className="mb-3 mt-3" />
                                    <img src={eho2} alt="cardio" style={{ width: "110%", height: "150px" }} />
                                </div>
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", overflow: "hidden" }} className="p-1">
                                <div className="d-flex flex-wrap justify-content-start">
                                    <p className="w-100 fw-bold mb-1">M-Mod</p>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "32px", textAlign: "left" }}>Ao:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.ao ? cardioExam.ao + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "42px", textAlign: "left" }}>IVSd:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.ivsd ? cardioExam.ivsd + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "42px", textAlign: "left" }}>LVDS:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.lvds ? cardioExam.lvds + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "30px", textAlign: "left" }}>EF:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.ef ? cardioExam.ef + "%" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "32px", textAlign: "left" }}>LA:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.la ? cardioExam.la + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "42px", textAlign: "left" }}>LVDd:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.lvdd ? cardioExam.lvdd + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "42px", textAlign: "left" }}>EDV:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.edv ? cardioExam.edv + "ml" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "30px", textAlign: "left" }}>FS:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.fs ? cardioExam.fs + "%" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "32px", textAlign: "left" }}>RVD:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.rvd ? cardioExam.rvd + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "42px", textAlign: "left" }}>PWDd:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.pwdd ? cardioExam.pwdd + "mm" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "42px", textAlign: "left" }}>ESV:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.esv ? cardioExam.esv + "ml" : ""}</p>
                                    </div>
                                    <div className="input-group align-items-center" style={{ width: "25%" }}>
                                        <p style={{ width: "45px", textAlign: "left" }}>Ao asc:</p>
                                        <p style={{ textAlign: "left", fontWeight: "bold" }}>{cardioExam.lv ? cardioExam.lv + "mm" : ""}</p>
                                    </div>

                                    <p className="w-100 fw-bold mb-1 mt-2">Dopler</p>
                                    <div className="w-100 d-flex mb-2 align-items-center">
                                        <div style={{ width: "30%" }} className="p-0">
                                            <p>Митрална валвула</p>
                                            {isMultiLang ? <p>Valvula Mitrale</p> : null}
                                        </div>
                                        <div style={{ border: "1px solid #212529", width: "70%", height: "26px", overflow: "hidden", lineHeight: "16px" }} className="d-flex align-items-center ps-1 pe-1 ">
                                            <span style={{ maxHeight: "100%" }}>{lang === "al" ? cardioExam.valvula_mitrale_al : cardioExam.valvula_mitrale}</span>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex mb-2 align-items-center">
                                        <div style={{ width: "30%" }} className="p-0">
                                            <p>Аортна валвула</p>
                                            {isMultiLang ? <p>Valvula Aortale</p> : null}
                                        </div>
                                        <div style={{ border: "1px solid #212529", width: "70%", height: "26px", overflow: "hidden", lineHeight: "16px" }} className="d-flex align-items-center ps-1 pe-1 ">
                                            <span style={{ maxHeight: "100%" }}>{lang === "al" ? cardioExam.valvula_aortale_al : cardioExam.valvula_aortale}</span>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex mb-2 align-items-center">
                                        <div style={{ width: "30%" }} className="p-0">
                                            <p>Трикуспидна валвула</p>
                                            {isMultiLang ? <p>Valvula Trikuspidale</p> : null}
                                        </div>
                                        <div style={{ border: "1px solid #212529", width: "70%", height: "26px", overflow: "hidden" }} className="p-1">
                                            <span style={{ maxHeight: "100%" }}>{lang === "al" ? cardioExam.valvula_trikuspidale_al : cardioExam.valvula_trikuspidale}</span>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex mb-1 align-items-center">
                                        <div style={{ width: "30%" }} className="p-0">
                                            <p>Пулмонална валвула</p>
                                            {isMultiLang ? <p>Valvula Pulmonale</p> : null}
                                        </div>
                                        <div style={{ border: "1px solid #212529", width: "70%", height: "26px", overflow: "hidden" }} className="p-1">
                                            <span style={{ maxHeight: "100%" }}>{lang === "al" ? cardioExam.valvula_pulmonale_al : cardioExam.valvula_pulmonale}</span>
                                        </div>
                                    </div>

                                    <div className="input-wrapper" style={{ marginRight: "0", marginTop: "0", width: "100%" }}>
                                        <b>{isMultiLang ? "Заклучок/Përfundim" : "Заклучок"}</b>
                                        <div className="form-control p-1" style={{ border: "1px solid #212529", height: "135px", lineHeight: fontSize3 + 5 + "px", fontSize: fontSize3 + "px", overflow: "hidden", whiteSpace: "pre-wrap" }}>
                                            {lang === "al" ? cardioExam.conclusion_al : cardioExam.conclusion}
                                        </div>
                                    </div>
                                    <div className="font-size w-100 d-flex justify-content-end">
                                        <select value={fontSize3} onChange={(e) => setFontSize3(Number(e.target.value))}>
                                            <option value="15">15px</option>
                                            <option value="14">14px</option>
                                            <option value="13">13px</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="page-break" style={{ margin: isMultiLang ? "5rem" : "6rem" }}></div>

                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", height: "115px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Дополнителни иследувања </span> <br />
                                {isMultiLang ? <span>Ekzaminime plotësuese</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "115px", overflow: "hidden", lineHeight: fontSize4 + 5 + "px", fontSize: fontSize4 + "px", whiteSpace: "pre-wrap" }} className="p-1">
                                {lang === "al" ? cardioExam.further_investigation_al : cardioExam.further_investigation}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize4} onChange={(e) => setFontSize4(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex" style={{ fontSize: "13px", marginTop: "16px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Дијагноза </span> <br />
                                {isMultiLang ? <span>Diagnoza</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "115px", overflow: "hidden", lineHeight: fontSize5 + 5 + "px", fontSize: fontSize5 + "px", whiteSpace: "pre-wrap" }} className="p-1">
                                {lang === "al" ? cardioExam.diagnose_al : cardioExam.diagnose}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize5} onChange={(e) => setFontSize5(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Терапија </span> <br />
                                {isMultiLang ? <span>Terapia</span> : null}
                            </div>
                            <div className="" style={{ border: "1px solid #212529", width: "76%", borderRadius: "5px", overflow: "hidden" }}>
                                <div style={{ width: "100%" }}>
                                    {medications.length > 0 ? (
                                        <>
                                            <div className="d-flex">
                                                <div className="py-1" style={{ width: "52%", borderRight: "1px solid #212529" }}></div>
                                                <div className="text-center py-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                    {isMultiLang ? (
                                                        <span>
                                                            Наутро
                                                            <br />
                                                            Mëngjes
                                                        </span>
                                                    ) : (
                                                        "Наутро"
                                                    )}
                                                </div>
                                                <div className="text-center py-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                    {isMultiLang ? (
                                                        <span>
                                                            Ручек
                                                            <br />
                                                            Mesditë
                                                        </span>
                                                    ) : (
                                                        "Ручек"
                                                    )}
                                                </div>
                                                <div className="text-center py-1" style={{ width: "16%", lineHeight: "16px" }}>
                                                    {isMultiLang ? (
                                                        <span>
                                                            Вечер
                                                            <br />
                                                            Mbrëmje
                                                        </span>
                                                    ) : (
                                                        "Вечер"
                                                    )}
                                                </div>
                                            </div>
                                            {medications.map((item, index) => {
                                                return (
                                                    <div className="d-flex" key={`therapy_${index}`} style={{ borderTop: "1px solid #212529" }}>
                                                        <div className="p-1" style={{ width: "52%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                            {item.medication}
                                                        </div>
                                                        <div className=" text-center p-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                            {item.morning}
                                                        </div>
                                                        <div className=" text-center p-1" style={{ width: "16%", lineHeight: "16px", borderRight: "1px solid #212529" }}>
                                                            {item.lunch}
                                                        </div>
                                                        <div className=" text-center p-1" style={{ width: "16%", lineHeight: "16px" }}>
                                                            {item.evening}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <div className="d-flex align-items-center p-1">
                                            <div style={{ width: "50%" }}>Без терапија</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Препорака </span> <br />
                                {isMultiLang ? <span>Rekomandim</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "115px", overflow: "hidden", lineHeight: fontSize6 + 5 + "px", fontSize: fontSize6 + "px", whiteSpace: "pre-wrap" }} className="p-1">
                                {lang === "al" ? cardioExam.recommendation_al : cardioExam.recommendation}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize6} onChange={(e) => setFontSize6(Number(e.target.value))}>
                                <option value="15">15px</option>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex justify-content-end doctor-sign">
                            <div style={{ borderBottom: "1px solid #212529", width: "250px", paddingBottom: "50px" }}>
                                <div className="m-0 fw-bold">{lang === "al" ? "Dr." + doctor.name_latin : "Др." + doctor.name_cyrilic}</div>
                                <div className="m-0">{isMultiLang ? doctor.speciality_latin : doctor.speciality_cyrilic}</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
